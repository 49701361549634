.page-link {
    position: relative;
    display: block;
    color: #009d4b;
    background-color: #ffffff;
}

.page-link:hover {
    z-index: 2;
    color: #009d4b;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-item.active .page-link {
    z-index: 1;
    color: #ffffff;
    background-color: #009d4b;
    border-color: #009d4b;
}

a:hover {
    color: rgba(0, 0, 0, 0.87)
}

